<template>
  <div id="app" v-if="phoneShow == false" class="apps">
    <div class="home-header" v-show="tabbarShow == false">
      <header-nav ref="headerNav"></header-nav>
    </div>

    <router-view />

    <!-- <div class="home-bottom" v-if="tabbarShow == false">
      <header-bottom></header-bottom>
    </div> -->
  </div>
</template>
<script>
import headerNav from "@/components/nav/header.vue";
import headerBottom from "@/components/nav/bottom.vue";

export default {
  components: {
    headerNav,
    headerBottom,
  },
  data() {
    return {
      phoneShow: false,
      tabbarShow: true,
    };
  },
  watch: {
    $route: {
      handler(newValue, oldValue) {
        this.$refs.headerNav.tokenMet();
      },
      deep: true,
    },
  },

  created() {
    //平台、设备和操作系统
    var system = {
      win: false,
      mac: false,
      xll: false,
    };
    //检测平台
    var p = navigator.platform;
    system.win = p.indexOf("Win") == 0;
    system.mac = p.indexOf("Mac") == 0;
    system.x11 = p == "X11" || p.indexOf("Linux") == 0;

    if (system.win || system.mac || system.xll) {
      //PC端
    } else {
      //移动端跳转的链接
      this.phoneShow = true;
      alert("用户您好，请在电脑端打开该网址");
    }
  },
  mounted() {
  
  },
};
</script>


<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {
  margin: 0;
  padding: 0;
}

.home-header {
  height: 120px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10;
}

.van-toast--default {
  text-align: center;
  z-index: 111111 !important;
}

.van-toast--default .van-loading {
  text-align: center;
  margin: 10px auto;
}

.home-bottom {
  width: 100%;
  height: 56px;
}

.apps {
}
</style>
