
module.exports = {
    language: {
        name: '英文',
        text: 'EN/',
    },
    nav: {
        header_one: ''
    },

}
