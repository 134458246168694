
module.exports = {
    language: {
        name: '繁体',
        text: 'EN/',
    },
    message: {
        message_1: '郵箱格式有誤!',
        message_2: '請先輸入郵箱！',
        message_3: '登錄成功',
        message_4: '尊敬的',
        message_5: '用戶',
        message_6: '淩晨',
        message_7: '當前時間',
        message_8: '請先勾選協議！',
        message_9: '當前密碼不壹致！',
        message_10: '註冊通過！',
        message_11: '您暫未實名認證，請先實名認證才可進入對應區域',
        message_12: '提現成功！',
        message_13: '復制成功！',
        message_14: '您暫未實名認證，請先進入個人中心-實名認證後即可購買',
        message_15: '請先輸入金額！',
        message_16: '請先確認協議！',
        message_17: '您暫未簽署本人真實姓名',
        message_18: '加載中...',
        message_19: '請先同意協議！',
        message_20: '綁定成功！',
        //----手机号
        message_21: '手機號格式有誤',
        message_22: '請先輸入手機號！',
        message_23: '請先添加銀行卡',
        message_24: '银联在线支付中',
        message_25: '',

    },
    header: {
        header_1: '繁体',
        header_2: '客戶登錄',
        header_3: '個人中心',
        header_4: '安全退出',
        header_5: '關於我們',
        header_6: '服務對象',
        header_7: '業務範圍',
        header_8: '洞察見解',
        header_9: '監管單位',
        header_10: '聚焦基金',
        header_11: '聯系我們',
    },
    home: {
        home_1: '開啟財富之門 共赴璀璨未來',
        home_2: '我們的願景',
        home_3: '公司將繼續砥礪前行，不斷創新，為投資者創造更多的價值，成為行業的引領者。我們的目標是：',
        home_4: '•  擴大在亞洲市場的影響力。',
        home_5: '•  持續優化和創新我們的投資產品。',
        home_6: '•  成為投資者首選的財富管理夥伴。',
        home_7: '我們的優點',
        home_8: '•  保護客戶資產： 嚴格的風控體系確保客戶資產安全。',
        home_9: '•  多樣化的投資選擇： 提供多元化的投資產品，包括股權基金、證券基金和混合型基金，以滿足不同投資者的需求。',
        home_10: '•  全球監管： 持有多個國家和地區的金融牌照，確保合規運營。',
        home_11: '•  高效執行： 快速響應市場變化，提供高效的交易和管理服務。',
        home_12: '•  專屬客戶服務： 提供1對1客戶經理服務，全方位支持客戶的投資需求。',
        home_13: '公司簡介',
        home_14: '長觀資本有限公司是壹家資產管理公司，專註於資金管理、基金管理、證券交易、股權投資等業務，覆蓋新加坡、香港及中國金融市場的主要領域。公司持有新加坡金融管理局頒發的執照。',
        home_15: '長觀資本憑借多年在金融領域的深耕細作，以專業、穩健和創新為基石，致力於為投資者提供優質的基金產品和卓越的服務。',
        home_16: '個人投資者',
        home_17: '機構投資者',
        home_18: '金融中介',
        home_19: '專屬客戶經理提供個性化咨詢。',
        home_20: '24/5全天候客戶支持。',
        home_21: '定期投資報告和市場分析。',
        home_22: '【最佳方案】股指期貨：指數基金與差價合約優劣分析',
        home_23: '貴金屬是什麽？如何投資貴金屬？ 黃金交易指南',
        home_24: '加息步履不停！如何利用利差交易“躺賺”？',
        home_25: '認識軸心點指標及計算方法',
        home_26: '【新手必讀】美股四大指數是什麽？',
        home_27: '【諧波理論】如何應用初始伽利形態抓住買賣機會',
        home_28: '了解更多',
        home_29: 'Type 1牌照',
        home_30: 'Type 4牌照',
        home_31: 'Type 9牌照',
        home_32: '基金名稱｜代碼',
        home_33: '基金類型',
        home_34: '基金總額',
        home_35: '已購金額',
        home_36: '周期(天)',
        home_37: '月化率',
        home_38: '已購份額',
        home_39: '操作',
        home_40: '購買',
        home_41: '香港九龍尖東麽地道62號永安廣場1201A室',
    },
    register: {
        register_1: '郵箱登錄',
        register_2: '郵箱密碼',
        register_3: '請輸入郵箱',
        register_4: '請輸入郵箱驗證碼',
        register_5: '發送驗證碼',
        register_6: '秒',
        register_7: '請輸入郵箱密碼',
        register_8: '還沒賬戶？去註冊',
        register_9: '登錄',
        register_10: '同意',
        register_11: '《用戶服務協議》',
        register_12: '及',
        register_13: '《客戶隱私政策》',
        //--------------注册页面
        register_14: '賬號註冊',
        register_15: '郵箱號碼：',
        register_16: '請輸入您的郵箱號碼',
        register_17: '郵箱驗證碼：',
        register_18: '請輸入您的郵箱驗證碼',
        register_19: '設置密碼：',
        register_20: '請設置密碼',
        register_21: '確認密碼：',
        register_22: '請再次確認密碼',
        register_23: '邀請碼：',
        register_24: '請輸入邀請碼【選填】',
        register_25: '註冊',
        register_26: '提交',
        //----手机号
        register_27: '手機號碼：',
        register_28: '請先輸入手機號碼',
        register_29: '手機驗證碼：',
        register_30: '請先輸入手機驗證碼',


    },
    personal: {
        personal_1: '已認證',
        personal_2: '未認證',
        personal_3: '我的錢包',
        personal_4: '實名認證',
        personal_5: '我的銀行卡',
        personal_6: '我的邀請碼',
        personal_7: '修改密碼',
        //-----个人中心
        personal_8: '資產收益圖',
        personal_9: '近壹月',
        personal_10: '近三月',
        personal_11: '近半年',
        personal_12: '近壹年',
        personal_13: '持有基金',
        personal_14: '人民幣',
        personal_15: '基金名稱',
        personal_16: '購買時間',
        personal_17: '類型',
        personal_18: '年化率',
        personal_19: '累計收益(元)',
        personal_20: '購買金額',
        personal_21: '總收益',
        //---------我的钱包
        personal_22: '錢包余額',
        personal_23: '提現',
        personal_24: '賬戶ID',
        personal_25: '交易記錄',
        personal_26: '創建時間',
        personal_27: '交易類型',
        personal_28: '金額（元）',
        personal_29: '狀態',
        personal_30: '進行中',
        personal_31: '成功',
        personal_32: '過期',
        personal_33: '異常',
        personal_34: '失敗',
        personal_35: '提現到：',
        personal_36: '請選擇',
        personal_37: '請輸入提現金額',
        personal_38: '最多可提現',
        personal_39: '提現手續：',
        personal_40: '確認',
        //-------我的银行卡
        personal_41: '刪除',
        personal_42: '添加銀行卡',
        personal_43: '是否解綁該銀行卡？',
        personal_44: '為了給您提供晚上的基金交易及其他服務，需您綁定銀行卡',
        personal_45: '所屬銀行：',
        personal_46: '請選擇所在銀行',
        personal_47: '銀行卡號：',
        personal_48: '請輸入您本人名下的儲蓄卡號',
        personal_49: '輸入卡號後會智能識別銀行卡種，銀行卡要求及支付限額等詳細情況對比，為確保您能正常購買和贖回基金，請您綁定壹類賬戶銀行卡若您綁定的是二類卡，款項劃付可能會因限額原因導致無法正常入賬',
        personal_50: '真實姓名',
        personal_51: '請輸入真實姓名',
        personal_52: '身份證號',
        personal_53: '請輸入身份證號',
        personal_54: '銀行預留手機號',
        personal_55: '請輸入銀行預留手機號',
        personal_56: '點擊“確認”同意',
        personal_57: '《長觀資本快捷付業務協議》',
        //--------我的邀请码
        personal_58: '復制',
        //--------实名认证
        personal_59: '認證狀態',
        personal_60: '未通過/未認證',
        personal_61: '認證通過',
        personal_62: '填寫資料',
        personal_63: '認證結果',
        personal_64: '認證信息',
        personal_65: '請輸入您想邦定的銀行卡護名',
        personal_66: '為確保賬號安全及合規性，所填寫的手機號碼的實名認證信息必須與本人真實姓名完全吻合，任何不壹致的情況都將導致審核流程無法通過',
        personal_67: '請輸入您的身份證號碼',
        personal_68: '真實姓名：',
        personal_69: '身份證號：',
        //---------佣金钱包
        personal_70: '賬戶錢包',
        personal_71: '傭金錢包',
        personal_72: '基金錢包',
        personal_73: '賬戶中心',
        personal_74: '累計購買基金總額',
        personal_75: '購買基金余額',
        //-------
        personal_76: '操作',
        personal_77: '贖回',
        personal_78: '轉讓',
        personal_79: '基金購買時間',
        personal_80: '個月',
        personal_81: '基金成功後',
        personal_82: '的手續費，是否同意？',
        personal_83: '少於',
        personal_84: '大於',
        personal_85: '或等於',
        personal_86: '將扣除',
        personal_87: '將不會扣除',
        //--------按钮
        personal_88: '取消',
        personal_89: '同意',
        personal_90: '請選擇銀行卡',
        personal_91: '確認贖回',
        personal_92: '確認轉讓',
        personal_93: '',
        personal_94: '',

        //---------基金钱包
    },
    particulars: {
        particulars_1: '首頁',
        particulars_2: '基金產品',
        particulars_3: '基金詳情',
        particulars_4: '基本信息',
        particulars_5: '基金名稱：',
        particulars_6: '基金代碼：',
        particulars_7: '基金簡稱： ',
        particulars_8: '基金類型：',
        particulars_9: '成立日期：',
        particulars_10: '基金管理人：',
        particulars_11: '基金經理：',
        particulars_12: '基金托管人：',
        particulars_13: '投資範圍：',
        particulars_14: '風險收益特征：',
        particulars_15: '費率結構',
        particulars_16: '申購金額M(元)(含申購費)',
        particulars_17: '申購費率',
        particulars_18: '按筆收取',
        particulars_19: '以上',
        particulars_20: '元',
        particulars_21: '風險提示',
        particulars_22: '尊敬的投資者:',
        particulars_23: '投資有風險，投資需謹慎。公開募集證券投資基金(以下簡稱“基金”)是壹種長期投資工具，其主要功能是分散投資，降低投資單壹證券所帶來的個別風險。基金不同於銀行儲蓄等能夠提供固定收益預期的金融工具，當您購買基金產品時，既可能按持有份額分享基金投資所產生的收益，也可能承擔基金投資所帶來的損失。基金銷售機構根據法規要求對投資者類別、風險承受能力和基金的風險等級進行劃分，並提出適當性匹配意見。本基金法律文件中涉及基金風險特征的表述與基金銷售機構對基金的風險評級可能不壹致，您在做出投資決策之前，請仔細閱讀基金合同、基金招募說明書和基金產品資料概要等產品法律文件和本風險揭示書，充分認識本基金的風險收益特征和產品特性，認真考慮本基金存在的各項風險因素，並根據自身的投資目的、投資期限、投資經驗、資產狀況等因素充分考慮自身的風險承受能力，在了解產品情況及銷售適當性意見的基礎上，理性判斷並謹慎做出投資決束。根據有關法律法規，基金管理人易方達基金管理有限公司做出如下風險揭示：',
        particulars_24: '壹、依據投資對象的不同,基金分為股票基金、混合基金、債券基金、貨幣市場基金、基金中基金、商品基金等不同類型，您投資不同類型的基金將獲得不同的收益預期，也將承擔不同程度的風險。壹般來說，基金的收益預期越高，您承擔的風險也越大。',
        particulars_25: '二、您應當充分了解基金定期定額投資和零存整取等儲蓄方式的區別。定期定額投資是引導投資者進行長期投資、平均投資成本的壹種簡單易行的投資方式，但並不能規避基金投資所固有的風險，不能保證投資者獲得收益，也不是替代儲蓄的等效理財方式。',
        particulars_26: '三、基金在投資運作過程中可能面臨各種風險，投資本基金的壹般風險包括市場風險、流動性風險、管理風險、稅收風險、技術風險和合規風險等。巨額贖回風險是開放式基金所特有的壹種風險，即當單個開放日基金的凈贖回申請超過基金總份額的百分之十時，您將可能無法及時贖回申請的全部基金份額，或您贖回的款項可能延緩支付。',
        particulars_27: '四、投資本基金可能遇到的風險包括:投資風險，包括因整體政治、經濟、社會等環境因素對證券市場價格產生影響的市場風險，基金管理人在基金管理實施過程中產生的基金管理風險，流動性風險(包括但不限於特定投資標的流動性較差風險、巨額贖回風險、啟用擺動定價或側袋機制等流動性風險管理工具帶來的風險等)，本基金的投資範圍包括資產支持證券、存托憑證等品種，可能給本基金帶來額外風險，以及本基金法律文件中涉及基金風險特征的表述與銷售機構對基金的風險評級可能不壹致的風險，等等。',
        particulars_28: '五、基金管理人承諾以誠實信用、勤勉盡責的原則管理和運用基金資產，但不保證本基金壹定盈利，也不保證最低收益。本基金的過往業績及其凈值高低並不預示其未來業績表現，基金管理人管理的其他基金的業績並不構成對本基金業績表現的保證。易方達基金管理有限公司提醒您基金投資的“買者自負"原則，在做出投資決策後，基金運營狀況與基金凈值變化引致的投資風險，由您自行負擔。基金管理人、基金托管人、基金銷售機構及相關機構不對基金投資收益做出任何承諾或保證。',
        particulars_29: '六、中國證監會對本基金募集的核準，並不表明其對本基金的價值和收益作出實質性判斷或保證，也不表明投資於本基金沒有風險。基金管理人依照恪盡職守、誠實信用、謹慎勤勉的原則管理和運用基金財產，但不保證基金壹定盈利，也不保證最低收益。基金投資者自依基金合同取得基金份額，即成為基金份額持有人和基金合同的當事人。各方當事人因《基金合同》而產生的或與《基金合同》有關的壹切爭議應盡量通過協商、調解途徑解決，如經友好協商未能解決的最終將通過仲裁方式處理，詳見《基金合同》。',
        particulars_30: '七、您應當通過基金管理人或具有基金銷售業務資格的其他機構購買贖回基金，具體基金銷售機構名單詳見基金管理人網站公示。',
        //--------基金购买
        particulars_31: '基金購買',
        particulars_32: '輸入金額',
        particulars_33: '提交並付款',
        particulars_34: '完成',
        particulars_35: '基金名稱',
        particulars_36: '基金代碼',
        particulars_37: '月化率',
        particulars_38: '周期(天)',
        particulars_39: '購買金額',
        particulars_40: '請輸入金額',
        particulars_41: '已閱讀',
        particulars_42: '《基金投資告知函》、《基金產品資料概要》、《基金合同》、《招募說明書》',
        particulars_43: '等法律文件，確認是本人自主決定購買，符合本人投資目標',
        particulars_44: '長觀資本精選混合型證券投資基金基金產品資料概要更新.pdf',
        particulars_45: '投資有風險，投資需謹慎。公開募集證券投資基金(以下簡稱“基金”)是壹種長期投資工具，其主要功能是分散投資，降低投資單壹證券所帶來的個別風險。基金不同於銀行儲蓄等能夠提供固定收益預期的金融工具，當您購買基金產品時，既可能按持有份額分享基金投資所產生的收益，也可能承擔基金投資所帶來的損失。基金銷售機構根據法規要求對投資者類別、風險承受能力和基金的風險等級進行劃分，並提出適當性匹配意見。本基金法律文件中涉及基金風險特征的表述與基金銷售機構對基金的風險評級可能不壹致。',
        particulars_46: '用戶簽名處',
        particulars_47: '放棄並返回',
        particulars_48: '我已知悉',
        particulars_49: '清空簽名',
        particulars_50: '確認簽名',
        //-----------收银台
        particulars_51: '收銀臺',
        particulars_52: '前收費',
        particulars_53: '選擇支付方式',
        particulars_54: '查看銀行卡支付限額',
        particulars_55: '個人網銀',
        particulars_56: '企業網銀',
        particulars_57: '支持手機銀行',
        particulars_58: '不支持手機銀行',
        //---------
        particulars_59: '',
        particulars_60: '',
        particulars_61: '',
        particulars_62: '',
        particulars_63: '',

    },
    commons: {
        commons_0: '申購',
        commons_1: '贖回',
        commons_2: '提現',
        //----
        commons_3: '凍結',
        commons_4: '正常',
        commons_5: '部回贖回',
        commons_6: '完全贖回',
        commons_7: '贖回中',
        commons_8: '鎖定中',
        commons_9: '',
        commons_10: '',
        commons_11: '',
    }

}